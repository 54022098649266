import React, { useState, useEffect } from 'react'
import { StaticQuery, graphql, navigate } from 'gatsby'
import { v4 } from 'uuid'
import * as R from 'ramda'
import { FaPhone, FaEnvelope, FaMapMarkerAlt, FaFacebook, FaInstagram, FaLinkedin } from "react-icons/fa";
import Select from 'react-select'
import Image from '../components/Image'
import LinkComponent from '../components/LinkComponent'
import { localeOptions, getLocalisedObjectName, getLocalisationUrl } from "../utils"

export const FooterTemplate = ({
  settings,
  location
}) => {
  const locationClasses = () => {
    if (location.pathname.includes('project-management')
        || location.pathname.includes('custom-homes')
        || location.pathname.includes('portfolio')) {
          return "project-group-colors"
        }
        return "";
  };
  const [hasMounted, setHasMounted] = useState(false);
  const [emailValue, setEmailValue] = useState("");
  const updateEmail = (e) => setEmailValue(e.target.value);
  const path = !!location ? location.pathname : null;
  const localisedFooterComponents = getLocalisedObjectName(path, 'footerComponents');
  const footerComponents = settings[localisedFooterComponents];

  const [locale, setLocale] = useState(localeOptions[0]);
  const changeLocale = (value) => {
    const newLocale = R.find(R.propEq('value', `${value.value}`))(localeOptions);
    setLocale(newLocale)
    return navigate(value.value);
  }
  const getCorrectLocale = (path, locale) => {
    const currentLocale = getLocalisationUrl(path);
    if (currentLocale !== "zh-hant" && currentLocale !== "zh-hans" && currentLocale !== "jp") {
      return localeOptions[0];
    }
    return R.find(R.propEq('value', `/${currentLocale}/`))(localeOptions);
  }

  useEffect(() => {
    setHasMounted(true);
  }, []);

  if (!hasMounted) {
    return null;
  }

  const {
    informationObject,
    nav,
    contactObject
  } = footerComponents;
  return (
    <footer className={`footer has-text-white-ter ${locationClasses()}`}>
      <div className="content has-text-white-ter footer-top section">
        <div className="container has-text-white-ter">
          <div className="columns">
            <div className="column is-3">
              <section className="menu">
                <h2>{informationObject.title}</h2>
                <div>{informationObject.text} </div>
              </section>
              <div className="social">
                <a target="_blank" rel="noopener noreferrer" title="facebook" href={settings.socials[0].url}>
                  <FaFacebook style={{ color: "black" }} />
                </a>
                <a target="_blank" rel="noopener noreferrer" title="linkedin" href={settings.socials[1].url}>
                  <FaLinkedin style={{ color: "black" }} />
                </a>
                <a target="_blank" rel="noopener noreferrer" title="instagram" href={settings.socials[2].url}>
                  <FaInstagram style={{ color: "black" }} />
                </a>
              </div>
            </div>
            <div className="column is-3 footer-section">
              <section>
                <h2>{contactObject.title}</h2>
                <div className="footer-text"><a href={`tel:${contactObject.phone}`}><FaPhone className="footer-icon" />{contactObject.phone}</a></div>
                <div className="footer-text"><a href={`mailto:${contactObject.email}`}><FaEnvelope className="footer-icon" />{contactObject.email}</a></div>
                <a className="footer-text" href={contactObject.area.map_link}><FaMapMarkerAlt className="footer-icon" />{contactObject.area.address}</a>
              </section>
            </div>
            <div className="column is-3 footer-section">
              <section>
                <h2>{nav.title}</h2>
                {
                  nav.nav_items.map((menuItem, index) => (
                    <LinkComponent key={v4()} linkObject={menuItem.linkObject} className="footer-link">
                      {menuItem.label}
                    </LinkComponent>
                  ))
                }
                <Select
                  className="form-field select-form-field locale-selector"
                  options={localeOptions}
                  isSearchable={false}
                  defaultValue={getCorrectLocale(path, locale)}
                  onChange={(e) => changeLocale(e, path)}
                  placeholder="Locale"
                />
              </section>
            </div>
            <div className="column is-3 footer-section">
            <section>
                <h2>{contactObject.newsletterTitle}</h2>
                <div id="mc_embed_signup">
                  <form action="https://h2group.us3.list-manage.com/subscribe/post?u=c00f5861c978f3eea82bbf02f&amp;id=e748333941" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" className="validate" target="_blank" noValidate>
                    <div id="mc_embed_signup_scroll" className="mc-container">
                      <input type="email" value={emailValue} onChange={(e) => updateEmail(e)} name="EMAIL" className="mc-email" id="mce-EMAIL" placeholder={contactObject.newsletterPlaceholder} required />
                      <div style={{ position: "absolute", left: "-5000px" }} aria-hidden="true"><input type="text" name="b_c00f5861c978f3eea82bbf02f_e748333941" tabIndex="-1" defaultValue="" /></div>
                      <button type="submit" name="subscribe" id="mc-embedded-subscribe" className="mc-button"><FaEnvelope /></button>
                    </div>
                  </form>
                </div>
              </section>
            </div>

          </div>
        </div>
      </div>
      <div className="full-width section">
        <div className="container footer-bottom-section has-text-white-ter">
          <div className="container has-text-white-ter">
            <div className="columns">
              <div className="column is-2 logo-section">
                <LinkComponent linkObject={{ link: path, isExternal: false }}>
                  <Image imageInfo={{ image: settings.footerLogo }} alt="H2 Group logo" style={{ width: '50px' }} />
                </LinkComponent>
              </div>
              <div className="column is-5"></div>
              <div className="column is-5 has-text-right copyright">{settings.copyright}</div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default (props) => (
  <StaticQuery
    query={
      graphql`
        query footerQuery {
          markdownRemark(frontmatter: {templateKey: {eq: "footer"}}) {
          frontmatter {
            copyright
            footerLogo
            socials {
              company
              url
            }
            footerComponents {
              informationObject {
                title
                text
              }
              contactObject {
                title
                phone
                email
                area {
                  address
                  map_link
                }
                newsletterTitle
                newsletterPlaceholder
              }
              nav {
                title
                nav_items {
                  label
                  linkObject {
                    link
                    isExternal
                  }
                }
              }
            }
            footerComponents_jp {
              informationObject {
                title
                text
              }
              contactObject {
                title
                phone
                email
                area {
                  address
                  map_link
                }
                newsletterTitle
                newsletterPlaceholder
              }
              nav {
                title
                nav_items {
                  label
                  linkObject {
                    link
                    isExternal
                  }
                }
              }
            }
            footerComponents_zhhans {
              informationObject {
                title
                text
              }
              contactObject {
                title
                phone
                email
                area {
                  address
                  map_link
                }
                newsletterTitle
                newsletterPlaceholder
              }
              nav {
                title
                nav_items {
                  label
                  linkObject {
                    link
                    isExternal
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={(data) => <FooterTemplate {...props} settings={data.markdownRemark.frontmatter} />}
  />
);
